<template>
    <vx-card title="Calendario">
        <p>Aquí se configuran los parámetros que afectan la fecha de entrega de los pedidos.</p>

        <div v-if="initProgress" style="margin-top: 1rem; width: 100%">
            <vs-progress indeterminate color="primary"></vs-progress>
        </div>

        <!--Increment-->
        <div>
            <h6 class="mt-5">Incremento de días</h6>
            <div class="demo-alignment">
                <vs-input-number v-for="(c, index) in content.calendar" :key="index" v-model="c.increment"
                                 :label="getDay(c.day)"/>
            </div>
        </div>
        <!--End-->
        <!--Disabled dates-->
        <div class="mt-5">
            <h6 class="mb-4">Deshabilitar días</h6>
            <vc-date-picker
                    mode='multiple'
                    v-model='content.dates'/>
        </div>
        <!--End-->
        <div class="mt-5">
            <div class="flex mt-6 flex-wrap items-center" slot="footer">
                <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
                <div v-if="progress" style="margin-top: 1rem; width: 100%">
                    <vs-progress indeterminate color="primary"></vs-progress>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
  import trimString from "../mixins/trimString";

  const firebase = require("firebase")
  // Required for side-effects
  require("firebase/firestore")
  let db = firebase.firestore()

  export default {
    name: "Calendar",
    mixins: [
      trimString
    ],
    data() {
      return {
        content: {
          calendar: [],
          dates: []
        },
        progress: false,
        initProgress: false
      }
    },
    created() {
      this.initProgress = true
      db.collection("content").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.content.id = doc.id
          this.content.calendar = doc.data().calendar
          let date = {}
          doc.data().dates.forEach((d) => {
            date = d.toDate()
            this.content.dates.push(date)
          })
        })
        this.initProgress = false
      })
    },
    computed: {
      isFormValid() {
        return !this.errors.any()
      }
    },
    methods: {
      submitData() {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.progress = true

            // Content
            let contentRef = db.collection('content').doc(this.content.id)

            //Clone to remove the id
            let cloneContent = Object.assign({}, this.content)
            delete cloneContent.id

            contentRef.update({
              ...cloneContent,
              uid: firebase.auth().currentUser.uid,
              updatedAt: firebase.firestore.FieldValue.serverTimestamp()
            }).then(() => {
              this.progress = false
              this.$vs.notify({
                color: 'success',
                title: 'Calendario',
                text: 'Calendario modificado correctamente.'
              })
            }).catch(() => {
              this.progress = false
              this.$vs.notify({
                color: 'warning',
                title: '¡Oh no!',
                text: 'Hubo un error.'
              })
            })
          }
        })
      },
      getDay(number) {
        if (number === 0) {
          return 'Domingo'
        } else if (number === 1) {
          return 'Lunes'
        } else if (number === 2) {
          return 'Martes'
        } else if (number === 3) {
          return 'Miércoles'
        } else if (number === 4) {
          return 'Jueves'
        } else if (number === 5) {
          return 'Viernes'
        } else if (number === 6) {
          return 'Sábado'
        }
      }
    }
  }
</script>
